import Cookie from "js-cookie";
import { updateCookie, clearCookie } from "../util/cookies";
import { redirectToLogin } from "../util/util";
import api from "./api";

export function getToken() {
  return Cookie.get(process.env.REACT_APP_TOKEN);
}

export function authentication(email, password) {
  return api.post("/users/auth", {
    authUsername: email,
    authPassword: password,
  });
}
export function logout(redirect = null) {
  clearCookie();
  redirectToLogin(redirect);
}

export async function refreshToken(sessionId) {
  try {
    const response = await api.post(
      "/users/refresh_token",
      {
        sessionId,
      },
      { headers: { Authorization: getToken() } }
    );
    updateCookie(response);
    return response;
  } catch (error) {
    clearCookie();
    console.error(error);
    return false;
  }
}

export function getSession() {
  return Cookie.get("sessionId");
}
