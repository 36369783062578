import styled, { css } from "styled-components";
import { Form as FormFormik, Field } from "formik";
import icoEye from "../../assets/images/ico-eye.png";

export const Form = styled(FormFormik)`
  display: flex;
  flex-direction: column;
  width: 100%;

  .footer-card {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .create-account {
      width: 50%;
      display: block;
      font-size: 14px;
      color: #828282;
      a {
        color: #004c94;
        cursor: pointer;
        text-decoration: none;
        font-size: 15px;
      }
    }
  }
  .forget-password {
    display: block;
    font-size: 14px;
    color: #828282;
    font-size: 12px;
    a {
      color: #004c94;
      cursor: pointer;
      text-decoration: none;
      font-size: 13px;
    }
  }
`;

export const FormControl = styled.div`
  position: relative;
  margin-bottom: 10px;
  width: 100%;
  text-align: center;
  ${(props) =>
    props.margin &&
    css`
      margin: ${props.margin};
    `};
  ${(props) =>
    props.textAlign &&
    css`
      text-align: ${props.textAlign};
    `};
`;

export const Relative = styled.div`
  position: relative;
`;

export const CapsLock = styled.div`
  position: absolute;
  bottom: -15px;
  font-size: 12px;
  background: #eee;
  color: #555;
  padding: 0 5px;
  line-height: 22px;
  border: 1px solid #3898ec;
  z-index: 9;
  right: 10px;
  display: none;
  &.active {
    display: block;
  }
  &:before {
    content: "";
    position: absolute;
    top: -7px;
    right: 0;
    border-bottom: 7px solid #3898ec;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
  }
  &:after {
    content: "";
    position: absolute;
    top: -6px;
    right: 1px;
    border-bottom: 7px solid #eee;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
  }
`;
export const IcoView = styled.div`
  position: absolute;
  top: 6px;
  right: 20px;
  cursor: pointer;
  width: 25px;
  height: 25px;
  background: url("${icoEye}") no-repeat top center;
  &.slash {
    background-position: bottom center;
  }
`;
export const InputError = styled.div`
  font-size: 12px;
  color: #990000;
  text-align: left;
  margin-top: 3px;
`;

export const Input = styled(Field)`
  height: 38px;
  padding: 16px;
  width: 100%;
  margin-bottom: 10px;
  font-size: 14px;
  border-radius: 5px;
  border: 2px solid rgb(61, 61, 61, 0.52);
  box-sizing: border-box;
  transition: border 300ms ease;
  &:focus {
    border-color: #3898ec;
  }
`;
export const Label = styled.label`
  color: #152939;
  font-size: 14px;
  margin-bottom: 8px;
  width: 100%;
  text-align: left;
  display: block;
`;
export const Button = styled.button`
  background: #004c94;
  color: #fff;
  padding: 14px 0;
  font-size: 12px;
  min-width: 153px;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  margin: 0;
  cursor: pointer;
  &:hover {
    background-color: #ffef00;
    color: #313131;
  }
`;
