import React from "react";
import RenderMsg from "../components/_partials/RenderMsg";
import Logo from "../assets/images/logo-branca.png";

const Base = ({ children }) => {
  return (
    <>
      <div className="box-background">
        <div className="box-container">
          <RenderMsg />
          <div className="row">
            <div className="col-12 col-sm-6 mb-30">
              <img src={Logo} width={"100%"} alt="logo" />
              <div className="description">
                "Longe das benesses oficiais, mais perto do pulsar das ruas,
                para fazer germinar novamente a esperança"
              </div>
            </div>
            <div className="col-12 col-sm-6 mb-30">
              <div className="formSection">
                <div className="sectionHeader">
                  Bem-vindo. Acesse agora a rede social exclusiva do PSDB.
                </div>
                {children}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer">
        <div className="row">
          <div className="col-12 col-sm-6 footerLogo">
            <img src={Logo} width={"150px"} alt="logo" />
          </div>
          <div className="col-12 col-sm-6 footerText">
            © Copyright 2020 - PSDB
          </div>
        </div>
      </div>
    </>
  );
};

export default Base;
