import Cookie from "js-cookie";

export const createCookie = (response) => {
  if (window.location.origin.indexOf("localhost") !== -1) {
    Cookie.set(process.env.REACT_APP_TOKEN, response.data.token);
    Cookie.set("sessionId", response.data.sessionId);
  }

  Cookie.set(process.env.REACT_APP_TOKEN, response.data.token, {
    domain: process.env.REACT_APP_DOMAIN,
    secure: true,
  });
  Cookie.set("sessionId", response.data.sessionId, {
    domain: process.env.REACT_APP_DOMAIN,
    secure: true,
  });
};

export const updateCookie = (response) => {
  if (window.location.origin.indexOf("localhost") !== -1) {
    Cookie.set(process.env.REACT_APP_TOKEN, response.data.token);
  }
  Cookie.set(process.env.REACT_APP_TOKEN, response.data.token, {
    domain: process.env.REACT_APP_DOMAIN,
    secure: true,
  });
};

export const clearCookie = () => {
  localStorage.clear();
  sessionStorage.clear();
  if (window.location.origin.indexOf("localhost") !== -1) {
    Cookie.remove(process.env.REACT_APP_TOKEN);
  }

  Cookie.remove(process.env.REACT_APP_TOKEN, {
    domain: process.env.REACT_APP_DOMAIN,
  });
  Object.keys(Cookie.get()).forEach((item) => {
    Cookie.remove(item, {
      domain: process.env.REACT_APP_DOMAIN,
    });
  });
};
