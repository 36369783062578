import React from "react";
import { useModal } from "../../contexts/modalContext";
import { ModalBackground, ModalBox, ModalClose, ModalBody } from "./styled";

function Modal() {
  const modal = useModal();

  const close = () => {
    modal.setOpen(false);
  };

  if (!modal.open) return null;

  return (
    <>
      <ModalBackground onClick={close} />
      <ModalBox>
        <ModalClose onClick={close}>X</ModalClose>
        {!!modal.content && <ModalBody>{modal.content}</ModalBody>}
      </ModalBox>
    </>
  );
}

export default Modal;
