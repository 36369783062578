import React, { useEffect } from "react";

import { BrowserRouter, Route, Switch, useHistory } from "react-router-dom";
import Login from "./Login";
import { getToken, getSession } from "../services/auth";
import { refreshToken, logout } from "../services/auth";
import { redirectTo } from "../util/util";
import queryString from "query-string";

const Main = () => {
  const history = useHistory();
  const search = queryString.parse(history.location.search);

  const isValidToken = async (redirect) => {
    try {
      const resp = await refreshToken(getSession());
      if (resp) {
        redirectTo(redirect);
      }
      return resp;
    } catch (error) {
      return false;
    }
  };

  useEffect(() => {
    if (search && search.logout) {
      logout(search.logout);
    } else if (getToken() && getSession()) {
      if (search && search.refresh) {
        isValidToken(search.refresh);
      } else {
        isValidToken();
      }
    }
  }, []);

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" component={Login} />
      </Switch>
    </BrowserRouter>
  );
};

export default Main;
