export const dict = {
  pt_BR: {
    "Sign In": "Entrar",
    "Sign Up": "Cadastre-se",
    "Sign Out": "Sair",
    "Forgot Password": "Esqueci a senha",
    Username: "Usuário",
    Password: "Senha",
    "Change Password": "Mudar senha",
    "New Password": "Nova senha",
    Email: "Email",
    "Phone Number": "Número do telefone",
    "Confirm a Code": "Confirmar um código",
    "Confirm Sign In": "Confirmar ínicio de sessão",
    "Confirm Sign Up": "Confirmar cadastro",
    "Back to Sign In": "Voltar para o login",
    "Send Code": "Enviar código",
    Confirm: "Confirmar",
    "Resend a Code": "Reenviar um código",
    Skip: "Pular",
    submit: "Entrar",
    submiting: "Entrando",
    Verify: "Verificar",
    "Verify Contact": "Verificar contato",
    Code: "Código",
    "Account recovery requires verified contact information":
      "Para recuperar sua conta é necessário verificar suas informações de contato",
    "User does not exist": "Email não existe",
    "User already exists": "Email já cadastrado",
    "Incorrect username or password": "Usuário/Email ou Senha incorretos",
    "Invalid password format":
      "Formato da senha inválido. Use letras e números",
    "Invalid phone number format": `Número de telefone inválido. Ex.: +12345678900`,
    "The following fields need to be filled out: ":
      "Verifique os seguintes campos: ",
    "Authentication Fail: Os dados informados para acesso estão incorretos.":
      "Os dados informados para acesso estão incorretos.",
    "Sign in to your account":
      "Bem-vindo. Acesse agora a rede social exclusiva do PSDB.",
    "placeholder.email": "Seu email",
    "placeholder.password": "Sua senha",
    "Enter your email": "Seu email",
    "Enter your code": "Seu código",
    "Forget your password? ": "Esqueceu sua senha? ",
    "Reset password": "Criar nova senha",
    "No account? ": "Sem conta? ",
    "Create Account": "Crie uma conta",
    "Create account": "Criar conta",
    "Create a new account": "Crie sua conta",
    "Have an account? ": "Já possui uma conta? ",
    "Sign in": "Login",
    "Reset your password": "Criar uma nova senha",
    "Username cannot be empty": "Usuário não pode ser vazio",
    "null invocation failed due to configuration.":
      "É necessário preencher a senha",
    "An account with the given email already exists.":
      "Já existe um usuário com esse email",
    "Confirmation Code *": "Já existe um usuário com esse email",
    "Full Name": "Nome Completo",
    Continue: "Continuar",
    "The following fields need to be filled out: Full Name, Email, Password":
      "Os seguintes campos precisam ser preenchidos: Nome Completo, Email, Senha.",
    "The following fields need to be filled out: Email, Password":
      "Os seguintes campos precisam ser preenchidos: Email, Senha.",
    "The following fields need to be filled out: Full Name, Password":
      "Os seguintes campos precisam ser preenchidos: Nome Completo, Senha.",
    "The following fields need to be filled out: Full Name, Email":
      "Os seguintes campos precisam ser preenchidos: Nome Completo, Email.",
    "The following fields need to be filled out: Password":
      "A Senha é obrigatoria.",
    "The following fields need to be filled out: Email":
      "O Email é obrigatório.",
    "The following fields need to be filled out: Full Name":
      "O Nome Completo é obrigatório.",
    "Password did not conform with policy: Password not long enough":
      "A Senha não é longa o suficiente.",
    "Invalid email address format.": "Formato de Email não é válido",
    "Username/client id combination not found.": "Email não encontrado",
    "Fill in your details":
      "Preencha seus dados e tenha acesso ao conteúdo agora mesmo",
    "Lost your code? ": "Perdeu o seu código? ",
    "Resend Code": "Reenviar código",
    "Confirmation Code": "Código de confirmação",
    "Confirm Password": "Confirmar Senha",
    "Code cannot be empty": "O código não pode estar vazio",
    "Invalid verification code provided, please try again.":
      "Código de verificação inválido, tente novamente.",
    "Attempt limit exceeded, please try after some time.":
      "Limite de tentativa excedido. Tente depois de algum tempo.",
  },
};
