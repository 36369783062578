import React, { useContext, useState, createContext } from "react";

const ModalContext = createContext();

const ModalProvider = (props) => {
  const [open, setOpen] = useState(false);
  const [content, setContent] = useState(null);

  return (
    <ModalContext.Provider value={{ open, setOpen, content, setContent }}>
      {props.children}
    </ModalContext.Provider>
  );
};

export const useModal = () => {
  const context = useContext(ModalContext);

  if (!context)
    throw new Error("useModal must be used within a provider ModalProvider");

  return { ...context };
};

export default ModalProvider;
