import Cookie from "js-cookie";

export const createCookie = (response) => {
  if (!response || !response.hasOwnProperty("idToken")) {
    redirectToLogin();
  }
  if (window.location.origin.indexOf("localhost")) {
    Cookie.set(process.env.REACT_APP_TOKEN, response.idToken.jwtToken);
  }
  Cookie.set(process.env.REACT_APP_TOKEN, response.idToken.jwtToken, {
    domain: process.env.REACT_APP_DOMAIN,
  });
};

export const clearCookie = () => {
  localStorage.clear();
  if (window.location.origin.indexOf("localhost")) {
    Cookie.remove(process.env.REACT_APP_TOKEN);
  }
  Cookie.remove(process.env.REACT_APP_TOKEN, {
    domain: process.env.REACT_APP_DOMAIN,
  });
};

export const hasAdmin = () => {
  const params = new URLSearchParams(window.location.search);
  return params.get("adm") === "true";
};

export const redirectTo = (redirect = "") => {
  if (redirect) {
    console.log(atob(redirect));
    window.location.href = atob(redirect);
  } else if (hasAdmin()) {
    window.location.href = `${process.env.REACT_APP_ADMIN_URL}`;
  } else {
    window.location.href = `${process.env.REACT_APP_SOCIAL_URL}`;
  }
};

export const redirectToLogin = (redirect = null) => {
  if (redirect) {
    if (hasAdmin()) {
      window.location.href = `/?login=${redirect}&adm=true`;
    } else {
      window.location.href = `/?login=${redirect}`;
    }
  } else {
    if (hasAdmin()) {
      window.location.href = `/?login=${btoa(
        `${process.env.REACT_APP_ADMIN_URL}`
      )}&adm=true`;
    } else {
      window.location.href = `/?login=${btoa(
        `${process.env.REACT_APP_SOCIAL_URL}`
      )}`;
    }
  }
};

export const getRedirectUrl = () => {
  const params = new URLSearchParams(window.location.search);
  const routes = ["signUp", "renew", "logout", "login"];
  let redirect = "";
  routes.forEach((route) => {
    if (params.get(route) !== null) {
      redirect = atob(params.get(route));
    }
  });
  redirectTo(redirect);
};
