import React, {useEffect, useState} from 'react';

const RenderMsg = () => {
  const [message, setMessage] = useState('');

  useEffect(() => {
    const msgParam = new URLSearchParams(window.location.search);
    const msg = msgParam.get('msg');
    if (msg){
      setMessage(decodeURIComponent(escape(atob(msg))));
    }

    return () => {
      setMessage('');
    };
  }, []);

  if (!message) {
    return <div/>;
  }

  return (
    <div style={{
      backgroundColor: '#004c94',
      color: '#ffffff',
      padding: '15px 30px',
      width: '100%',
      textAlign: 'center',
    }}>{message}</div>
  );
};

export default RenderMsg;
