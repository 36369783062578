import React, { useEffect } from "react";
import "./App.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Main from "./pages/Main";
import Base from "./theme/Base";
import Modal from "./components/modal";
import ModalContext from "./contexts/modalContext";

const App = () => {
  return (
    <ModalContext>
      <Base>
        <BrowserRouter>
          <Switch>
            <Route path="/" component={Main} />
          </Switch>
        </BrowserRouter>
      </Base>
      <Modal />
    </ModalContext>
  );
};

export default App;
