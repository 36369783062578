import React, { useEffect, useState } from "react";
import {
  Input as InputStyled,
  IcoView,
  CapsLock,
  Relative,
} from "../../assets/styles/GlobalStyles";

function InputPassword(props) {
  const [viewPass, setViewPass] = useState(false);
  const [capsLock, setCapsLock] = useState(false);

  const verifyCapsLock = (event) => {
    setCapsLock(event.getModifierState && event.getModifierState("CapsLock"));
  };
  useEffect(() => {
    document.addEventListener("keydown", verifyCapsLock);
    return () => {
      document.removeEventListener("keydown", verifyCapsLock);
    };
  });

  const enableInput = (e) => {
    e.target.readOnly = false;
  };

  return (
    <Relative>
      <InputStyled
        {...props}
        type={viewPass ? "text" : "password"}
        readOnly
        onFocus={enableInput}
        autoComplete="off"
      />
      <CapsLock className={capsLock && "active"}>Caps Lock Ativado</CapsLock>
      <IcoView
        role="button"
        tabIndex="0"
        aria-label="Exibir conteúdo"
        className={viewPass && "slash"}
        // onMouseDown={() => setViewPass(true)}
        // onMouseUp={() => setViewPass(false)}
        // onMouseOut={() => setViewPass(false)}
        onClick={() => setViewPass((prev) => !prev)}
        // onBlur={() => setViewPass(false)}
      />
    </Relative>
  );
}

export { InputPassword };
