import styled from "styled-components";

export const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 60%);
  z-index: 99998;
`;

export const ModalBox = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  width: 60vw;
  min-height: 15vh;
  margin-left: -35vw;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  background: #fff;
  z-index: 99999;
  border-radius: 5px;
  padding: 15px;
`;

export const ModalHeader = styled.div``;
export const ModalClose = styled.div`
  text-align: end;
  margin-bottom: 30px;
  cursor: pointer;
`;
export const ModalBody = styled.div`
  text-align: center;
`;
