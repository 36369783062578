import React from "react";
import { ErrorMessage, Field, Formik } from "formik";
import {
  Form,
  FormControl,
  InputError,
  Label,
  Button,
} from "../assets/styles/GlobalStyles";
import { useHistory } from "react-router-dom";
import { Input, InputPassword } from "../components/_partials";
import * as Yup from "yup";
import { dict } from "../vocabularies/disct";
import { authentication } from "../services/auth";
import { createCookie } from "../util/cookies";
import { useModal } from "../contexts/modalContext";
import { redirectTo } from "../util/util";
import queryString from "query-string";

const translate = (text) => {
  return dict.pt_BR[text];
};

const Login = (props) => {
  const modal = useModal();
  const history = useHistory();
  const search = queryString.parse(history.location.search);

  return (
    <>
      <div>
        <Formik
          enableReinitialize
          initialValues={{
            email: "",
            password: "",
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email("Email inválido.")
              .required("Campo obrigatório."),
            password: Yup.string()
              .min(6, "Senha inválida.")
              .required("Campo obrigatório."),
          })}
          onSubmit={async (values, actions) => {
            try {
              const resp = await authentication(values.email, values.password);
              createCookie(resp);
              if (search && search.login) {
                console.log();
                redirectTo(search.login);
              } else {
                redirectTo();
              }
            } catch (error) {
              modal.setOpen(true);
              modal.setContent(translate(error.response.data.detail));
              console.log(error, error.response);
              console.error(error);
            }
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <FormControl>
                <Label>E-mail *</Label>
                <Field
                  name="email"
                  placeholder={translate("placeholder.email")}
                  component={Input}
                />
                <ErrorMessage name="email" component={InputError} />
              </FormControl>
              <FormControl>
                <Label>Senha *</Label>
                <InputPassword
                  name="password"
                  placeholder={translate("placeholder.password")}
                />
                <ErrorMessage name="password" component={InputError} />
              </FormControl>
              <span className="forget-password">
                {translate("Forget your password? ")}
                <a target="_blank" href={process.env.REACT_APP_RESET_PASSWORD}>
                  {translate("Reset password")}
                </a>
              </span>

              <div className="footer-card">
                <span className="create-account">
                  {translate("No account? ")}
                  <a
                    target="_blank"
                    href={process.env.REACT_APP_CREATE_ACCOUNT}
                  >
                    {translate("Create account")}
                  </a>
                </span>
                <FormControl margin="0" textAlign="end">
                  <Button type="submit" disabled={isSubmitting}>
                    {isSubmitting
                      ? translate("submiting")
                      : translate("submit")}
                  </Button>
                </FormControl>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default Login;
